<template>
  <div class="home">
    <h1>Bienvenido a Tambo Wasi</h1>
    <h4>Software Hotelero</h4>
    <p>Use e panel de la izquierda para administrar su negocio</p>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
    HelloWorld
  }
}
</script>
